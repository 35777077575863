import React, { useState } from "react";
import logo from "./logo.avif";
import telly from "./telly.svg";
import x from "./x.svg";
import aigf from "./aigf.mp4";
import one from "./1.png";
import two from "./2.png";
import three from "./3.png";

const Header = () => {
  return (
    <div className="flex flex-col md:flex-row w-full items-center justify-between gap-4">
      <img className="w-[200px]" src={logo} />
      <div className="flex gap-8">
        <a className="flex md:flex-row gap-2 items-center" href="https://twitter.com/AiGFsol" target="_blank">
          <img className="w-10" src={x} />
          <div className="text-[#f30b8e]">Twitter</div>
        </a>
        <a className="flex gap-2 items-center" href="https://t.me/AiGFonSol" target="_blank">
          <img className="w-10" src={telly} />
          <div className="text-[#f30b8e]">Telegram</div>
        </a>
      </div>
    </div>
  );
};

const Video = () => {
  return (
    <div className="flex items-center justify-center">
      <video className="w-[80%]" autoPlay controls loop src={aigf} />
    </div>
  );
};

const Mid = () => {
  return (
    <div className="flex flex-col items-center justify-center gap-4">
      <div className="text-2xl font-4xl text-[#f30b8e] font-semibold">AIGF</div>
      <div className="text-white text-center">
        INTRODUCING YOUR VERY OWN PERSONAL AI GF
      </div>
      <div className="text-white text-center">
        COMING TO SOL - FULFILL YOUR DEEPEST DESIRES AND FANTASY
      </div>
    </div>
  );
};
const Footer = () => {
  return (
    <div className="flex flex-col md:flex-row w-full items-center justify-between gap-4">
      <div className="text-[#f30b8e]">$AIGF</div>
      <div className="flex gap-8">
        <a className="flex md:flex-row gap-2 items-center" href="https://twitter.com/AiGFsol" target="_blank">
          <img className="w-10" src={x} />
          <div className="text-[#f30b8e]">Twitter</div>
        </a>
        <a className="flex gap-2 items-center" href="https://t.me/AiGFonSol" target="_blank">
          <img className="w-10" src={telly} />
          <div className="text-[#f30b8e]">Telegram</div>
        </a>
      </div>
    </div>
  );
};

export default function Home() {
  return (
    <div className="flex flex-col gap-14 p-8">
      <Header />
      <div className="text-[#f30b8e] text-center text-sm">62KSo2FidDi9U5K1d3wnNa4PiukAoAF9ZyXRBSajrFRe</div>
      <Video />
      <Mid />
      <div className="flex flex-col justify-around items-center sm:flex-row gap-4 py-8 pt-20">
        <div className="flex flex-col w-[80%] max-w-md justify-between gap-6 sm:w-[40%]">
          <div className="text-white text-center">
            Customisable features such as eyes, hair, tits, pussy and ass!
          </div>
        </div>
        <img className="w-[80%] sm:w-[30%] " src={one} alt="" />
      </div>
      <div className="flex flex-col justify-around items-center sm:flex-row gap-4 py-8 pt-20">
        <img className="w-[80%] sm:w-[30%] " src={two} alt="" />
        <div className="flex flex-col w-[80%] max-w-md justify-between gap-6 sm:w-[40%]">
          <div className="text-white text-center">
            Accessory pack including makeup, repairs, upgrades for your waifu!
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-around items-center sm:flex-row gap-4 py-8 pt-20">
        <div className="flex flex-col w-[80%] max-w-md justify-between gap-6 sm:w-[40%]">
          <div className="text-white text-center ">
            Free delivery for orders including 2 or more WAIFU!
          </div>
        </div>
        <img className="w-[80%] sm:w-[30%] " src={three} alt="" />
      </div>
      <Footer />
    </div>
  );
}
